import { useState, useEffect } from 'react';
import { fetchWithAuth } from '../hooks/authHelpers';

export const useTeamBuildingChallenge = () => {
    const [challenges, setChallenges] = useState([]);
    const [completedChallenges, setCompletedChallenges] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const getChallenges = async () => {
            try {
                setLoading(true);
                const [challengesData, completedChallengesData] = await Promise.all([
                    fetchChallenges(),
                    fetchCompletedChallenges()
                ]);
                setChallenges(challengesData);
                setCompletedChallenges(completedChallengesData);
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };

        getChallenges();
    }, []);

    const fetchChallenges = async () => {
        try {
            const response = await fetchWithAuth(`${process.env.REACT_APP_BACKEND_URL}/tbc`, 'GET');
            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.message || 'Failed to fetch team building challenges');
            }
            const data = await response.json();
            return data;
        } catch (error) {
            console.error('Error fetching team building challenges:', error);
            throw error;
        }
    };

    const fetchCompletedChallenges = async () => {
        try {
            const response = await fetchWithAuth(`${process.env.REACT_APP_BACKEND_URL}/tbc/completed`, 'GET');
            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.message || 'Failed to fetch completed challenges');
            }
            const data = await response.json();
            return data;
        } catch (error) {
            console.error('Error fetching completed challenges:', error);
            throw error;
        }
    };

    const completeChallenge = async (challengeId, team) => {
        try {
            const response = await fetchWithAuth(`${process.env.REACT_APP_BACKEND_URL}/tbc/${challengeId}/complete`, 'POST', { team });
            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.message || 'Failed to complete challenge');
            }
            const data = await response.json();
            // Fetch the updated list of completed challenges
            const updatedCompletedChallenges = await fetchCompletedChallenges();
            setCompletedChallenges(updatedCompletedChallenges);
            return data;
        } catch (error) {
            console.error('Error completing challenge:', error);
            throw error;
        }
    };

    //Returns hasOpened: boolean, packID: string
    const fetchSpecialPackStatus = async (packID) => {
        try {
            const response = await fetchWithAuth(`${process.env.REACT_APP_BACKEND_URL}/packs/special/${packID}/status`, 'GET');
            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.message || 'Failed to fetch special pack status');
            }
            const data = await response.json();
            return data;
        } catch (error) {
            console.error('Error fetching special pack status:', error);
            throw error;
        }
    }

    const openSpecialPack = async (packID) => {
        try {
            const response = await fetchWithAuth(`${process.env.REACT_APP_BACKEND_URL}/packs/special/${packID}/open`, 'POST');
            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.message || 'Failed to open special pack');
            }
            const data = await response.json();
            return data;
        } catch (error) {
            console.error('Error opening special pack:', error);
            throw error;
        }
    }

    return { challenges, completedChallenges, loading, error, completeChallenge, fetchSpecialPackStatus, openSpecialPack };
};

export default useTeamBuildingChallenge;