import React, { useState, useEffect, useRef } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../css/TeamBuildingChallenge/TeamBuildingChallenge.css';
import useTeamBuildingChallenge from '../../hooks/useTeamBuildingChallenge';
import Card from '../Card';
import Inventory from '../Inventory';

const TeamBuildingChallenge = ({ fullSize = false }) => {
    const [selectedChallenge, setSelectedChallenge] = useState(0);
    const [selectedPackStatus, setSelectedPackStatus] = useState(null);
    const { challenges, completedChallenges, loading, error, completeChallenge, fetchSpecialPackStatus, openSpecialPack } = useTeamBuildingChallenge();
    const [timeLeft, setTimeLeft] = useState([]);
    const [team, setTeam] = useState([]);
    const [submitError, setSubmitError] = useState(null);
    const [submitSuccess, setSubmitSuccess] = useState(null);
    const [requirementStatus, setRequirementStatus] = useState([]);
    const [openedCardsID, setOpenedCardsID] = useState([]);
    const cardRefs = useRef([]);
    const [isOpening, setIsOpening] = useState(true);
    const [allCardsOpened, setAllCardsOpened] = useState(false); // State to track if all cards are opened
    const [lockOpening, setLockOpening] = useState(false);
    const timers = useRef([]);
    const inventoryRef = useRef(null);

    const containerStyle = {
        justifyContent: challenges.length < 4 ? 'center' : 'flex-start'
    };

    const requirementToText = (requirement, format = 'long') => {
        const cardText = requirement.amount === 1 ? 'card' : 'cards';
        const minmaxText = requirement.minmax === 'min' ? 'above' : 'below';
        const minmaxTextShort = requirement.minmax === 'min' ? '>' : '<';
        const rarityMap = {
            1: 'Bronze',
            2: 'Silver',
            3: 'Gold',
            4: 'Legendary'
        };

        const capitalizeFirstLetter = (string) => {
            return string.charAt(0).toUpperCase() + string.slice(1);
        };

        if (format === 'short') {
            switch (requirement.category) {
                case 'teamAvgStats':
                    return `Avg ${capitalizeFirstLetter(requirement.stat)} stat (${minmaxTextShort}${requirement.value})`;
                case 'stats':
                    return `${capitalizeFirstLetter(requirement.stat)} stat (${minmaxTextShort}${requirement.value})`;
                case 'rarity':
                    return `${rarityMap[requirement.value]} ${cardText}`;
                case 'team':
                    return `Unique teams`;
                case 'boosts':
                    return `${requirement.boost.name} boost (${minmaxTextShort}${requirement.value})`;
                default:
                    return '';
            }
        } else {
            switch (requirement.category) {
                case 'teamAvgStats':
                    return `Average ${capitalizeFirstLetter(requirement.stat)} stat of team ${minmaxText} ${requirement.value}`;
                case 'stats':
                    return `Minimum ${requirement.amount} ${cardText} with a ${capitalizeFirstLetter(requirement.stat)} stat of ${minmaxText} ${requirement.value}`;
                case 'rarity':
                    return `Minimum ${requirement.amount} ${rarityMap[requirement.value]} ${cardText}`;
                case 'team':
                    return `Amount of unique teams: ${requirement.value}`;
                case 'boosts':
                    return `Minimum ${requirement.amount} ${cardText} with a ${requirement.boost.name} boost ${minmaxText} ${requirement.value}`;
                default:
                    return '';
            }
        }
    };

    const requirementStatusText = (requirement, averageStats) => {
        const cardText = requirement.amount === 1 ? 'card' : 'cards';
        const minmaxText = requirement.minmax === 'min' ? 'above' : 'below';
        const rarityMap = {
            1: 'Bronze',
            2: 'Silver',
            3: 'Gold',
            4: 'Legendary'
        };

        switch (requirement.category) {
            case 'teamAvgStats':
                let avgStatName = requirement.stat;
                if (requirement.stat === 'awping') { avgStatName = 'sniping'; }
                if (requirement.stat === 'rating') { avgStatName = 'firepower'; }
                return `${averageStats[avgStatName] ? averageStats[avgStatName].toFixed(2) : '0'}/${requirement.value}`;
            case 'stats':
                let statName = requirement.stat;
                if (requirement.stat === 'awping') { statName = 'sniping'; }
                if (requirement.stat === 'rating') { statName = 'firepower'; }
                return requirement.minmax === 'min'
                    ? `${team.filter(card => card.card.player.stats[0][statName] > requirement.value).length}/${requirement.amount}`
                    : `${team.filter(card => card.card.player.stats[0][statName] < requirement.value).length}/${requirement.amount}`;
            case 'rarity':
                return `${team.filter(card => card.card.tier === requirement.value).length}/${requirement.amount}`;
            case 'team':
                const uniqueTeams = [...new Set(team.map(card => card.card.player.team._id))];
                return `${uniqueTeams.length}/${requirement.value}`;
            case 'boosts':
                return requirement.minmax === 'min'
                    ? `${team.filter(card => card.card.boosts.some(boost => boost.boost === requirement.boost._id && boost.value > requirement.value)).length}/${requirement.amount}`
                    : `${team.filter(card => card.card.boosts.some(boost => boost.boost === requirement.boost._id && boost.value < requirement.value)).length}/${requirement.amount}`;
            default:
                return '';
        }
    };

    const renderStars = (difficulty) => {
        const stars = [];
        for (let i = 0; i < 5; i++) {
            stars.push(
                <span key={i} className={i < difficulty ? 'star filled' : 'star'}>
                    &#9733;
                </span>
            );
        }
        return stars;
    };

    const addCard = (card) => {
        setTeam(prevTeam => {
            if (prevTeam.length >= 5) { return prevTeam; }
            if (prevTeam.find(c => c.card._id === card._id)) { return prevTeam; }
            if (prevTeam.find(c => c.player && c.player._id === card.player._id)) { return prevTeam; }

            // Find the first available position between 1 and 5
            let position = [1, 2, 3, 4, 5].find(p => !prevTeam.find(c => c.position === p));

            // Add the card to the team with the assigned position
            return [...prevTeam, { card: card, player: card.player._id, points: 0, position: position }];
        });
    }

    const removeCard = (card) => {
        setTeam(prevTeam => {
            const updatedTeam = prevTeam.filter(c => c.card._id !== card.card._id);
            return updatedTeam;
        });
    };

    const handleSubmitTeam = async (team) => {
        try {
            await completeChallenge(challenges[selectedChallenge]._id, team);
            setSubmitError(null);
            setTeam([]);

            // Refetch inventory when a challenge is completed
            if (inventoryRef.current) {
                console.log("Calling refetchInventory after completing challenge");
                inventoryRef.current.refetchInventory();
            }
        } catch (error) {
            setSubmitSuccess(null);
            setSubmitError(error.message);
        }
    }

    const isChallengeCompleted = (challenge) => {
        return Array.isArray(completedChallenges) && completedChallenges.some(completed => completed.challengeId === challenge._id);
    };

    const handleOpenPack = async (packID) => {
        if (!lockOpening) {
            setLockOpening(true); // Lock the function to prevent multiple executions
            try {
                // Reset these states before opening new pack
                setAllCardsOpened(false);
                setIsOpening(true); // Reset the opening state to true
                cardRefs.current = []; // Clear card refs
                clearTimers(); // Clear any existing timers

                const data = await openSpecialPack(packID);
                console.log("DATA", data);
                setOpenedCardsID(data.cards);
            } catch (error) {
                console.error('Error opening pack:', error);
            } finally {
                setLockOpening(false); // Ensure the lock is released after the operation
            }
        }
    };

    const quickOpen = () => {
        setLockOpening(false);
        setIsOpening(false);
        clearTimers();
        setAllCardsOpened(true); // Set the state to true when quick open is used
    };

    const clearTimers = () => {
        timers.current.forEach(timer => clearTimeout(timer));
        timers.current = [];
    };


    const justifyContent = () => {
        const packContainer = document.querySelector('.OpenPack-content');

        const checkCardContainer = () => {
            const cardContainer = document.getElementById('card');
            if (packContainer && cardContainer) {
                const totalCardsWidth = openedCardsID.length * cardContainer.clientWidth;
                const isOverflowing = totalCardsWidth > packContainer.clientWidth;

                if (isOverflowing) {
                    packContainer.style.justifyContent = 'flex-start';
                } else {
                    packContainer.style.justifyContent = 'center';
                }

                // Clear the interval once the cardContainer is found and processed
                clearInterval(intervalId);
            }
        };

        // Retry every second until the cardContainer is found
        const intervalId = setInterval(checkCardContainer, 1000);
    };

    useEffect(() => {
        // Clear existing timers whenever openedCardsID changes
        clearTimers();

        if (openedCardsID.length > 0) {
            setIsOpening(true); // Ensure isOpening is true when new cards are loaded

            // Open the first card after half a second
            const firstTimer = setTimeout(() => {
                if (cardRefs.current[0]) {
                    cardRefs.current[0].openCard();
                }
                if (openedCardsID.length === 1) {
                    setLockOpening(false);
                    setAllCardsOpened(true); // Set the state to true when all cards are opened
                }
            }, 500); // 1 second delay for the first card
            timers.current.push(firstTimer);

            // Open the remaining cards with a delay
            openedCardsID.slice(1).forEach((cardID, index) => {
                const timer = setTimeout(() => {
                    if (cardRefs.current[index + 1]) { // Adjust index to account for the first card
                        cardRefs.current[index + 1].openCard();
                    }
                    // Unlock opening after the last card is opened
                    if (index === openedCardsID.length - 2) {
                        setLockOpening(false);
                        setAllCardsOpened(true); // Set the state to true when all cards are opened
                    }
                }, 1000 + (index + 1) * 3500);
                timers.current.push(timer);
            });
        }

        // Cleanup function to clear timers when component unmounts or when openedCardsID changes
        return () => {
            clearTimers();
        };
    }, [openedCardsID]);

    useEffect(() => {
        justifyContent();
        console.log("ALL CARDS OPENED", allCardsOpened);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [openedCardsID, allCardsOpened]);

    useEffect(() => {
        const calculateAverageStats = () => {
            if (team.length === 0) {
                return {};
            }

            const totalStats = team.reduce((acc, card) => {
                for (const [stat, value] of Object.entries(card.card.player.stats[0])) {
                    acc[stat] = (acc[stat] || 0) + value;
                }
                return acc;
            }, {});

            const avgStats = Object.keys(totalStats).reduce((acc, stat) => {
                acc[stat] = totalStats[stat] / team.length;
                return acc;
            }, {});

            return avgStats;
        };

        const isRequirementFulfilled = (requirement, averageStats) => {
            let status = false;
            switch (requirement.category) {
                case "rarity":
                    status = team.filter(card => card.card.tier === requirement.value).length >= requirement.amount;
                    break;
                case "team":
                    const uniqueTeams = [...new Set(team.map(card => card.card.player.team._id))];
                    status = uniqueTeams.length === requirement.value;
                    break;
                case "stats":
                    let statName = requirement.stat;
                    if (requirement.stat === 'awping') { statName = 'sniping'; }
                    if (requirement.stat === 'rating') { statName = 'firepower'; }
                    status = requirement.minmax === "min"
                        ? team.filter(card => card.card.player.stats[0][statName] > requirement.value).length >= requirement.amount
                        : team.filter(card => card.card.player.stats[0][statName] < requirement.value).length >= requirement.amount;
                    break;
                case "teamAvgStats":
                    let avgStatName = requirement.stat;
                    if (requirement.stat === 'awping') { avgStatName = 'sniping'; }
                    if (requirement.stat === 'rating') { avgStatName = 'firepower'; }
                    const avgStatValue = averageStats[avgStatName] || 0;
                    status = requirement.minmax === "min"
                        ? avgStatValue >= requirement.value
                        : avgStatValue <= requirement.value;
                    break;
                case "boosts":
                    status = requirement.minmax === "min"
                        ? team.filter(card => card.card.boosts.some(boost => boost.boost === requirement.boost._id && boost.value > requirement.value)).length >= requirement.amount
                        : team.filter(card => card.card.boosts.some(boost => boost.boost === requirement.boost._id && boost.value < requirement.value)).length >= requirement.amount;
                    break;
                default:
                    status = false;
            }
            return {
                status,
                text: requirementStatusText(requirement, averageStats)
            };
        };

        if (challenges[selectedChallenge]) {
            const avgStats = calculateAverageStats();
            const status = challenges[selectedChallenge].requirements.map(requirement => isRequirementFulfilled(requirement, avgStats));
            setRequirementStatus(status);
        }
    }, [team, challenges, selectedChallenge]);

    useEffect(() => {
        if (challenges.length <= 3) { return; }
        const challengeContent = document.querySelector('.tbc-challenges-container');
        if (challengeContent) {
            const handleScroll = (event) => {
                if (event.deltaY !== 0) {
                    challengeContent.scrollLeft += event.deltaY;
                    event.preventDefault();
                }
            };
            challengeContent.addEventListener('wheel', handleScroll);
            return () => {
                challengeContent.removeEventListener('wheel', handleScroll);
            };
        }
    }, [challenges]);

    useEffect(() => {
        const updateCountdown = () => {
            const newTimeLeft = challenges?.map(challenge => {
                const endDate = new Date(challenge.endDate).getTime();
                const now = new Date().getTime();
                const distance = endDate - now;

                if (distance < 0) {
                    return 'Expired';
                }

                const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
                const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
                const seconds = Math.floor((distance % (1000 * 60)) / 1000);

                return `${hours}h ${minutes}m ${seconds}s`;
            });

            setTimeLeft(newTimeLeft);
        };

        updateCountdown();
        const interval = setInterval(updateCountdown, 1000);

        return () => clearInterval(interval);
    }, [challenges]);

    useEffect(() => {
        console.log(selectedChallenge);
        const fetchPackStatus = async () => {
            if (selectedChallenge !== null && challenges[selectedChallenge]) {
                const packStatus = await fetchSpecialPackStatus(challenges[selectedChallenge]?.reward._id);
                setSelectedPackStatus(packStatus.hasOpened);
            }
        };
        fetchPackStatus();
        setLockOpening(false);
        setOpenedCardsID([]);
        setAllCardsOpened(false);

        // Refetch inventory when selectedChallenge changes
        if (inventoryRef.current && challenges.length > 0) {
            console.log("Calling refetchInventory due to challenge change");
            inventoryRef.current.refetchInventory();
        }
    }, [selectedChallenge, challenges]);

    return (
        <div className={`main-container col-10 offset-1`}>
            <div className='scroll-container'>

                <div className='title-container' style={{ marginBottom: '0.5vw', position: 'relative' }}>
                    <div className='col-10 inventory-box'>
                        <div className='col-auto'>
                            <h1 className='title'>Team Building Challenge</h1>
                        </div>
                    </div>
                </div>

                <div className='tbc-challenges-main-container'>
                    <h1 className='title-inventory'>Challenges</h1>
                    <div className='tbc-challenges-container' style={containerStyle}>
                        {loading && <div className='loading'></div>}
                        {error && <h1 className='error'>{error}</h1>}
                        {!loading && !error && challenges.length === 0 && (
                            <h1>No challenges available</h1>
                        )}
                        {!loading && !error && challenges.length > 0 && (
                            challenges.map((challenge, index) => (
                                <div key={index} className={`tbc-challenge-wrapper`} onClick={() => setSelectedChallenge(index)}>
                                    <div className={`tbc-challenge ${selectedChallenge === index ? 'selected' : ''} ${isChallengeCompleted(challenge) ? 'completed' : ''}`}>
                                        <h1 className='tbc-challenge-name'>{challenge.name} {isChallengeCompleted(challenge) && "✔"}</h1>
                                        <p className='tbc-challenge-timer'>{timeLeft[index]}</p>
                                        <p className='tbc-challenge-desc'><span>Difficulty:</span> {renderStars(challenge.difficulty)}</p>
                                        <p className='tbc-challenge-desc'><span>Reward:</span> {challenge.reward.name}</p>
                                        <p className='tbc-challenge-desc'><span>Requirements:</span></p>
                                        <ul className='tbc-challenge-desc'>
                                            {challenge.requirements.map((requirement, index) => (
                                                <li key={index}>{
                                                    requirementToText(requirement)
                                                }</li>
                                            ))}
                                        </ul>
                                    </div>
                                </div>
                            ))
                        )}
                    </div>
                </div>

                {selectedChallenge !== null && !isChallengeCompleted(challenges[selectedChallenge]) ? (
                    <div>
                        <div className='event-page-container'>
                            <h1 className='title-inventory'>Team</h1>
                        </div>

                        <div className='tbc-challenge-main-container'>
                            <div className='tbc-challenge-team-container'>
                                <div className='tbc-challenge-requirements-container'>
                                    <h1 className='title-inventory'>Requirements</h1>

                                    <div className='tbc-challenge-requirements'>
                                        <table className='tbc-challenge-requirement'>
                                            <tbody>
                                                {challenges[selectedChallenge]?.requirements.map((requirement, index) => (
                                                    <tr key={index}>
                                                        <td>
                                                            <div className='requirements-status'>
                                                                <div className={`circle ${requirementStatus[index]?.status ? 'green' : 'red'}`}></div>
                                                            </div>
                                                        </td>
                                                        <td>{requirementToText(requirement, "short")}</td>
                                                        <td>
                                                            {requirementStatus[index] ? requirementStatus[index].text : ''}
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>

                                    </div>
                                </div>
                                <div className='event-page-team-container'>
                                    <div className='event-fantasy-team-container'>
                                        {Array.from({ length: 5 }).map((_, index) => {
                                            const card = team.find(c => c.position === index + 1);
                                            return (
                                                <div key={index} className='event-fantasy-player'>
                                                    {card ? (
                                                        <div className='card-point-container' onClick={() => removeCard(card)}>
                                                            <Card card={card.card} />
                                                        </div>
                                                    ) : (
                                                        <img className='fantasy-player-image' src={'https://static.hltv.org/images/playerprofile/bodyshot/unknown.png'} alt='player' />
                                                    )}
                                                </div>
                                            );
                                        })}
                                    </div>
                                    <button className='default-button submit-team-button' onClick={() => handleSubmitTeam(team)}>Submit Team</button>
                                    {submitError && <p className='error' style={{ alignSelf: 'center' }}>{submitError}</p>}
                                    {submitSuccess && <p className='success' style={{ alignSelf: 'center' }}>{submitSuccess}</p>}
                                </div>
                            </div>

                            <div className='event-page-inventory-container' style={{ position: 'relative' }}>
                                <Inventory
                                    ref={inventoryRef}
                                    onCardClick={addCard}
                                    fullSize={true}
                                    team={team}
                                />
                            </div>
                        </div>
                    </div>
                ) : (
                    <div>
                        <div className='event-page-container'>
                            <h1 className='title-inventory'>Reward</h1>
                        </div>

                        <div className='OpenPack-container' style={{ width: '100%' }}>
                            <div className='OpenPack-content'>
                                {selectedChallenge !== null && !selectedPackStatus ? (
                                    openedCardsID.length === 0 ? (
                                        <div className='tbc-challenge-reward-opened'>
                                            <button className='default-button close-button' onClick={() => { handleOpenPack(challenges[selectedChallenge]?.reward._id) }}>Open</button>
                                        </div>
                                    ) : (
                                        <div className='tbc-OpenPack-container'>
                                            <div className='tbc-OpenPack-content'>
                                                {openedCardsID.map((card, index) => (
                                                    <div key={index} className='cardDisplay-container' style={{ paddingBottom: 0, marginBottom: 0 }}>
                                                        <Card ref={(el) => (cardRefs.current[index] = el)} cardID={card._id} size={"small"} isOpening={isOpening} />
                                                    </div>
                                                ))}
                                            </div>
                                            <div className='OpenPack-footer' style={{ minHeight: '5vw' }}>
                                                {!allCardsOpened && (
                                                    <button className='default-button close-button' onClick={quickOpen}>Quick Open</button>
                                                )}
                                            </div>
                                        </div>
                                    )

                                ) : (
                                    <div className='tbc-challenge-reward-opened'>
                                        <h1>Redeemed</h1>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                )}

            </div>
        </div>
    );
};

export default TeamBuildingChallenge;