import React, { useContext, useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { ThemeContext } from '../context/ThemeContext';

const BackgroundVideo = () => {
  const { backgroundVideo } = useContext(ThemeContext);
  const [retryCount, setRetryCount] = useState(0);

  const handleVideoError = (e) => {
    console.error('Error loading video:', e);
    console.error('Video URL:', backgroundVideo);
    if (retryCount < 3) {
      setRetryCount(retryCount + 1);
      e.target.load();
    }
  };

  useEffect(() => {
    setRetryCount(0); // Reset retry count when backgroundVideo changes
  }, [backgroundVideo]);

  return ReactDOM.createPortal(
    <video
      className='app-background-video'
      src={backgroundVideo}
      autoPlay
      loop
      muted
      onError={handleVideoError}
    />,
    document.getElementById('background-video-root')
  );
};

export default BackgroundVideo;