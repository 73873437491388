import './App.css';
import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { useAuthContext } from './hooks/useAuthContext';
import { ThemeProvider } from './context/ThemeContext';
import Layout from './components/Layout';
import FrontPage from './components/FrontPage';
import LayoutGuest from './components/LayoutGuest';
import Welcome from './components/Welcome';
import Profile from './components/Profile';
import BackgroundVideo from './components/BackgroundVideo';
import PackPage from './components/PackPage';
import Inventory from './components/Inventory';
import EventPage from './components/EventPage';
import Leaderboard from './components/Leaderboard';
import Events from './components/Events';
import Market from './components/Market';
import HowToPlay from './components/HowToPlay/HowToPlay';
import TeamBuildingChallenge from './components/TeamBuildingChallenge/TeamBuildingChallenge';

function App() {
  const { user, loading } = useAuthContext();

  if (loading) {
    return (
      <div className='loader-overlay'>
        <div className='loader'></div>
      </div>
    );
  }

  return (
    <ThemeProvider>
      <div className='app-container'>
        <BackgroundVideo />
        <div className="app-content">
          <Routes>
            <Route path="/" element={user ? <Layout /> : <LayoutGuest />}>
              <Route index element={user ? <FrontPage /> : <Welcome />} />
              <Route path="packs" element={user ? <PackPage /> : <Navigate to="/" />} />
              <Route path="inventory" element={user ? <Inventory /> : <Navigate to="/" />} />
              <Route path="event/:event_id" element={user ? <EventPage /> : <Navigate to="/" />} />
              <Route path="events" element={user ? <Events /> : <Navigate to="/" />} />
              <Route path="market" element={user ? <Market /> : <Navigate to="/" />} />
              <Route path="leaderboard" element={user ? <Leaderboard /> : <Navigate to="/" />} />
              <Route path="profile" element={user ? <Profile /> : <Navigate to="/" />} />
              <Route path="howtoplay" element={user ? <HowToPlay /> : <Navigate to="/" />} />
              <Route path="tbc" element={user ? <TeamBuildingChallenge /> : <Navigate to="/" />} />
            </Route>
          </Routes>
        </div>
      </div>
    </ThemeProvider>
  );
}

export default App;